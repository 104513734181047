// 包含坐标拾取基类
export default {
  data() {
    return {
      zoom: 21,
      zoomNum: 12,
      temp: {
        pt: [],
        mk: [],
        iwOpenIndex: null
      },
      mapInfo: {
        cityName: '',
        cityCode: '',
        centerPoint: null
      },
      // 地址列表
      addressList: [],
      // 城市列表
      cityList: [],
      pageNum: 1,
      total: 0,
      pageSize: 3,
      searchText: '',
      resultInfo: '',
      isAddMarker: true,
      destZoom: 12,
      currentLng: 0,
      currentLat: 0,
      currentAddress: ''
    }
  },
  methods: {
     setInputPoint(a) {
       this.currentLocation.lng = a.lng
       this.currentLocation.lat = a.lat
    },
    initDrawMap() {
      this.searchText = ''
      this.addressList = []
      this.cityList = []
      this.resultInfo = ''
    },
    handleToPoint(item) {
      this.map.centerAndZoom(item.point, this.destZoom)
    },
    handleMouseOver(g) {
      if (this.temp.iwOpenIndex === g) {
        return
      }
      var i = this.temp.mk[g]
      var h = i.getIcon()
      h.setImageOffset(new this.BMap.Size(0, -250 - g * 25))
      i.setIcon(h)
      i.setTop(true, 1000100)
      this.temp.iwOpenIndex = g
    },
    handleMouseOut(g) {
      var i = this.temp.mk[g]
      var h = i.getIcon()
      h.setImageOffset(new this.BMap.Size(0, -g * 25))
      i.setIcon(h)
      i.setTop(false)
      this.temp.iwOpenIndex = null
    },

    clearLastResult() {
      this.temp.mk.forEach((p) => {
        this.map.removeOverlay(p)
      })
      this.temp.pt = []
      this.temp.mk = []
    },
    addMarker(a) {
      if (!this.isAddMarker) {
        return
      }
      const b = this.temp.pt[a]
      const d = new this.BMap.Icon('/images/markers.png', new this.BMap.Size(23, 25), {
        offset: new this.BMap.Size(10, 25),
        imageOffset: new this.BMap.Size(0, 0 - a * 25),
        infoWindowAnchor: new this.BMap.Size(12, 0)
      })
      const c = new this.BMap.Marker(b, {
        icon: d
      })
      this.map.addOverlay(c)
      this.temp.mk.push(c)
      return c
    },
    // 添加拾取坐标的marker
    addPickupMarker(point) {
      if (!this.isAddPickupMarker) {
        return
      }
      this.pickupOverlay && this.map.removeOverlay(this.pickupOverlay)
      const d = new this.BMap.Icon('/images/location.png', new this.BMap.Size(32, 32))
      const c = new this.BMap.Marker(point, {
        icon: d
      })
      this.map.addOverlay(c)
      this.pickupOverlay = c
      return c
    },

    changeSize(current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
      this.localSearch.gotoPage(this.pageNum)
    },
    handleLocationSearch(value) {
      if (!value) {
        return
      }
      this.address = value
      this.beginsearch(this.localSearch)
    },
    beginsearch(b, a) {
      if (!a) {
        b.setLocation(this.map)
      }
      b.search(this.address)
    },
    // 拾取坐标初始化
    initLocalSearch() {
      const d = {
        onSearchComplete: (w) => {
          this.clearLastResult()
          this.addressList = []
          this.cityList = []
          if (this.localSearch.getStatus() === window.BMAP_STATUS_SUCCESS) {
            const l = w.getCurrentNumPois()
            const k = w.getCurrentNumPois()
            const e = w.getNumPois()
            // let p = ''

            if (l === 1 && typeof w.getPoi(0).city === 'undefined') {
              // let f = 4
              // if (b && b._json && b._json.content && b._json.content.length == 2) {
              if (w && w.getNumPois() === 2) {
                // f = w.getPoi(1)
                this.resultInfo = w.keyword
              } else {
                if (w && w.city) {
                  this.resultInfo = '已切换至' + w.city
                }
              }
              this.map.centerAndZoom(w.getPoi(0).point, 12)
            } else {
              const pts = []
              this.addressList = []
              for (let z = 0; z < l; z++) {
                const h = w.getPoi(z)
                const q = h.title
                const u = h.address
                const v = h.point
                pts.push(v)
                // const A = v.lng + ',' + v.lat
                let x = q
                const j = h.type
                let t = '地址'
                if (x.length > 20) {
                  x = x.substring(0, 17) + '...'
                }
                if (j === 1) {
                  t = '途径公交车'
                }
                if (j === 3) {
                  t = '途径地铁'
                }
                this.addressList.push({
                  title: x,
                  address: t + '：' + u,
                  point: v
                })

                this.temp.pt.push(v)
                this.addMarker(z)
              }
              // 重置地图视野
              this.map.setViewport(pts)
              // 下一页
              this.pageNum = w.getPageIndex()
              // 总记录数
              this.total = e
              // 页大小
              this.pageSize = k
              this.resultInfo = '共找到' + this.total + '条结果'
              // marker绑定鼠标事件
              this.bindEvent()
              setTimeout(() => {
                const el = document.getElementById('addressList')
                if (el) {
                  el.scrollTop = 0
                }
              }, 0)
            }
          } else {
            // var y = ''
            this.totalCount = 0

            if (w.city !== '全国') {
              this.resultInfo = '在' + w.city + '及全国没有找到相关的地点。'
            } else {
              this.resultInfo = '在全国没有找到相关的地点。'
            }
            if (w.getCityList().length > 0) {
              if (w.city !== '全国') {
                this.resultInfo = '在<b>' + w.city + '</b>没有找到相关的地点。'
              } else {
                this.resultInfo = '在以下城市有结果，请您选择:'
              }
              const g = w.getCityList()
              this.cityList = []
              if (g.length > 0) {
                this.resultInfo = '在以下城市找到结果，请选择城市:'

                for (let z = 0; z < g.length; z++) {
                  this.cityList.push({
                    city: g[z].city,
                    num: g[z].numResults
                  })
                }
                console.log('cityList', JSON.stringify(this.cityList))
              }
            }
          }
        }
      }
      this.localSearch = new this.BMap.LocalSearch(this.map, d)
    },
    bindEvent() {
      if (!this.isAddMarker) {
        return
      }
      const d = this.temp.mk
      for (let f = 0; f < d.length; f++) {
        const h = f
        d[h].addEventListener('click', () => {})
        d[h].addEventListener('mouseover', () => {
          this.handleMouseOver(h)
        })
        d[h].addEventListener('mouseout', () => {
          this.handleMouseOut(h)
        })
      }
    },
    searchInthisCity(a) {
      this.localSearch.setLocation(a)
      this.beginsearch(this.localSearch, 'cityList')
    },
    setCurrentMapInfo(c, d) {
      const a = this.mapInfo
      const b = this.map.getZoom()
      a.cityName = c
      a.cityCode = d
      a.centerPoint = this.map.getCenter()
      this.zoomNum = b
    },
    getCurrentCityName() {
      var a = this.map.getZoom()
      var c
      var h = 10000
      if (a <= 7) {
        c = a
        this.setCurrentMapInfo('全国')
        return
      }
      const i = () => {
        var m = this.map.getBounds()
        var j = this.projection.lngLatToPoint(m.getSouthWest())
        var l = this.projection.lngLatToPoint(m.getNorthEast())
        var k = function (n) {
          return parseInt(n / 1000) * 1000
        }
        return k(j.x) + ',' + k(j.y) + ';' + k(l.x) + ',' + k(l.y)
      }
      const e = this.mapInfo.centerPoint
      const d = this.map.getCenter()
      const f = Math.sqrt((e.lng - d.lng) * (e.lng - d.lng) + (e.lat - d.lat) * (e.lat - d.lat))
      const g = () => {
        if (typeof _mapCenter === 'undefined') {
          return
        }
        var j = window._mapCenter
        var k = j.content
        if (!k) {
          return
        }
        this.setCurrentMapInfo(window._mapCenter.content.name, window._mapCenter.content.uid)
      }
      if (f > h || a !== c) {
        c = a
        const b = 'https://map.baidu.com/?newmap=1&qt=cen&b=' + i() + '&l=' + a
        this.scriptRequest(b, g, '_MAP_CENTER_', 'gbk')
      }
    },
    scriptRequest(url, echo, id, charset) {
      let script = null
      const isIe = /msie/i.test(window.navigator.userAgent)
      const scriptId = document.getElementById('_script_' + id)
      if (isIe && scriptId) {
        script = scriptId
      } else {
        if (scriptId) {
          scriptId.parentNode.removeChild(scriptId)
        }
        script = document.createElement('script')
        if (charset != null) {
          script.charset = charset
        }
        if (id != null && id !== '') {
          script.setAttribute('id', '_script_' + id)
        }
        script.setAttribute('type', 'text/javascript')
        document.body.appendChild(script)
      }
      var t = new Date()
      if (url.indexOf('?') > -1) {
        url += '&t=' + t.getTime()
      } else {
        url += '?t=' + t.getTime()
      }
      const _complete = () => {
        if (!script.readyState || script.readyState === 'loaded' || script.readyState === 'complete') {
          if (echo === 'null') {
          } else {
            if (typeof echo === 'function') {
              try {
                echo()
              } catch (e) {}
            } else {
              // eslint-disable-next-line no-eval
              window.eval(echo)
            }
          }
        }
      }
      if (isIe) {
        script.onreadystatechange = _complete
      } else {
        script.onload = _complete
      }
      script.setAttribute('src', url)
    }
  }
}
